import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import Header from "../Components/Header";
import "../css/terms.css";

const TDS = () => {
  const [websiteSettings, setWebsiteSettings] = useState(null);
  const [error, setError] = useState(null);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  // Fetch website settings
  const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}/settings/data`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setWebsiteSettings(data);
    } catch (error) {
      setError(error.message);
      console.error("Error fetching website settings:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [baseUrl]);

  return (
    <div className="">
      <div className="leftContainer">
        <Header />
        <div className="content mt-5 py-4 px-3">
          <div className="m-3">
            <h1>TDS Policy</h1>
              <div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      type="button"
                      aria-expanded="true"
                      className="accordion-button"
                    >
                      TDS Policy
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse show">
                    <div className="accordion-body">
                      <p>
                        In line with the provisions contained in{" "}
                        <a
                          target="_blank"
                          href="https://incometaxindia.gov.in/news/finance-bill-2023-highlights.pdf"
                          rel="noreferrer"
                        >
                          Finance Act, 2023
                        </a>
                        , following TDS policy is applicable for all players on
                        Rajasthaniludo from 1 Apr, 2023.
                      </p>
                      <ul className="mt-3 mb-3">
                        <li>
                          TDS is applicable at the time of withdrawal or deposit
                          refund.
                        </li>
                        <li>
                          30% TDS is applicable on any positive net winnings at
                          the time of withdrawal.
                        </li>
                        <li>
                          TDS is also applicable at the end of financial year
                          on year-end wallet balance by considering it as a
                          withdrawal amount. Post applying TDS on the remaining
                          balance (as per policy), the remaining amount will be
                          carried forward to the next financial year as deposit
                          balance.
                        </li>
                      </ul>
                      <p>
                        Here, Net winnings = Total Withdrawals - Total Deposits
                        (in a financial year). Apr 1 to Mar 31 duration is
                        considered a financial year.
                      </p>
                      <h4>How was TDS deducted before Apr 1, 2023:</h4>
                      <ul>
                        <li>
                          Before Apr 1 2023, TDS was deducted at a game level
                          when the winnings were greater than 10K.
                        </li>
                      </ul>
                      <p>
                        Example: Previously, if I won Rs 11000 in a game, then
                        30% TDS would be deducted i.e., 3300, and hence Rs 7700
                        would be credited in the withdrawal wallet.
                      </p>
                      {/* More content based on TDS Policy */}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default TDS;
